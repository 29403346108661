export const constants = {
  navBarItems: [
    { label: "Services", path: "/services" },
    { label: "FAQ", path: "/faq" },
    { label: "About", path: "/about" },
    { label: "Contact Us", path: "/contact-us" }
  ],
  socialItems: [
    {
      label: "Facebook",
      icon: "icons8-facebook-48.png",
      path: "https://www.facebook.com/profile.php?id=61565684476148"
    },
    {
      label: "LinkedIn",
      icon: "icons8-linkedin-48.png",
      path: "https://www.linkedin.com/company/aurora-rising-ltd/"
    }
  ]
};
