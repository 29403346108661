import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { constants } from "./navBarConstants.js";
import { Grid2, Link } from "@mui/material";
const pages = constants.navBarItems;
const socialItems = constants.socialItems;

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <AppBar position="static" id="navbar">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "-12px"
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ color: "#FFF" }}
              >
                <MenuIcon />
              </IconButton>
              {socialItems.map(social =>
                <Link
                  key={social.label}
                  href={social.path}
                  sx={{
                    color: "#1d1640",
                    textDecoration: "none",
                    maxWidth: "40px"
                  }}
                >
                  <Button
                    key={social.label}
                    tabIndex={-1}
                    onClick={handleCloseNavMenu}
                  >
                    <Box
                      component={"img"}
                      sx={{ width: "30px", height: "30px" }}
                      src={`/images/${social.icon}`}
                      alt={social.label}
                      target={"_blank"}
                    />
                  </Button>
                </Link>
              )}
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              {pages.map(page =>
                <MenuItem
                  key={`${page.label}-mobile`}
                  onClick={handleCloseNavMenu}
                >
                  <Link
                    href={page.path}
                    sx={{
                      textAlign: "center",
                      color: "#1d1640",
                      textDecoration: "none"
                    }}
                  >
                    {page.label}
                  </Link>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" }, width: "100%" }}>
            <Grid2
              container
              spacing={2}
              sx={{ width: "100%", alignItems: "center" }}
            >
              <Grid2 item size={5}>
                {pages.map(page =>
                  <Link
                    key={page.label}
                    href={page.path}
                    sx={{ color: "#1d1640", textDecoration: "none" }}
                  >
                    <Button
                      key={page.label}
                      tabIndex={-1}
                      onClick={handleCloseNavMenu}
                    >
                      {page.label}
                    </Button>
                  </Link>
                )}
              </Grid2>
              <Grid2 item size={2} offset={3}>
                {socialItems.map(social =>
                  <Link
                    key={social.label}
                    href={social.path}
                    sx={{ color: "#1d1640", textDecoration: "none" }}
                  >
                    <Button
                      key={social.label}
                      tabIndex={-1}
                      onClick={handleCloseNavMenu}
                    >
                      <Box
                        component={"img"}
                        src={`/images/${social.icon}`}
                        alt={social.label}
                        target={"_blank"}
                      />
                    </Button>
                  </Link>
                )}
              </Grid2>
            </Grid2>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
