import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2
} from "@mui/material";
import { constants } from "./faq.constants";
import FAQImage from "./../../assets/images/231099575.png";
import PageImage from "../../components/PageImage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqList = constants.FAQ;
const FAQ = () => {
  return (
    <Grid2 container spacing={2}>
      <Grid2 item size={12}>
        <PageImage image={FAQImage} />
      </Grid2>
      <Grid2 item size={12}>
        <h1>FAQ</h1>
      </Grid2>
      <Grid2 item size={12}>
        {faqList.map(faq => {
          return (
            <Accordion key={faq.question}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h3>
                  {faq.question}
                </h3>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  {faq.answer}
                </p>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid2>
    </Grid2>
  );
};

export default FAQ;
