export const constants = {
  services: [
    "General Inquiry",
    "Assessments",
    "Counselling Services",
    "Psychological and Behavioural Consulting",
    "FSCD Funded Services",
    "Supervision for Students & Professionals"
  ],
  schedulingText: [
    { tag: "h3", strong: null, text: `Schedule a Virtual Meet & Greet` },
    {
      tag: "p",
      strong: null,
      text: `At Aurora Rising Ltd., we're excited to partner with you on your journey. Scheduling a consultation is simple and designed to meet your needs. Here's how to get started:`
    },
    { tag: "h4", strong: null, text: `How to Schedule Your Appointment` },
    {
      tag: "p",
      strong: "Complete the Contact Form:",
      text: `Fill out our contact form with your details and service preferences. This will help us better understand your needs and expedite the scheduling process. Don't forget to throw in what time of day might work best for a meet and greet!`
    },
    {
      tag: "p",
      strong: "Select a Date and Time:",
      text: `Once we receive your form, our team will reach out to discuss available dates and times that fit your schedule. We offer flexible options to accommodate your busy life.`
    },
    {
      tag: "p",
      strong: "Confirmation:",
      text: `After finalizing your appointment, you will receive a confirmation email with all the details and any necessary instructions.`
    },
    { tag: "h4", strong: null, text: `Cancellation Policy` },
    {
      tag: "p",
      strong: null,
      text: `If you need to reschedule or cancel your appointment, please notify us at least 24 hours prior to your scheduled appointment. We appreciate your understanding as we work to support all our clients.`
    }
  ]
};
